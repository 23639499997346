import * as React from 'react';
import { useState, useEffect } from 'react';

import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import Divider from '@mui/material/Divider';

import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import Fade from '@mui/material/Fade';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import TextField from '@mui/material/TextField';

import './App.css';
import { width } from '@mui/system';

import { Html5QrcodeScanner } from 'html5-qrcode';
import { Paper } from '@mui/material';

var html5QrcodeScanner = null;

var qrScanEvent = () => {};

let _warehouseList = {};

var backendURL = 'https://contentgenerator.krakatoa.umbach.dev/warehouse/';
var _whid = '4045145295009';

_warehouseList[_whid] = {};
_warehouseList[_whid].code = _whid;
_warehouseList[_whid].name = 'Die Limo Orange-Lemongras';
_warehouseList[_whid].amount = 2;

_whid = '4045145295008';
_warehouseList[_whid] = {};
_warehouseList[_whid].code = _whid;
_warehouseList[_whid].name = 'Test';
_warehouseList[_whid].amount = 12;

function App() {
    const [isLoading, setLoading] = useState(false);

    const [warehouseList, setWarehouseList] = useState({});

    let inputAmountRef = React.createRef();

    let inputCodeRef = React.createRef();
    let inputNameRef = React.createRef();

    let inputCodeEditRef = React.createRef();
    let inputNameEditRef = React.createRef();
    let inputAmountEditRef = React.createRef();

    const [curEdit, setCurEdit] = React.useState({ type: 'none', item: {} });
    function closeCurEdit() {
        let _curEdit = { ...curEdit };
        _curEdit.type = 'none';
        setCurEdit(_curEdit);
    }

    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
        if (html5QrcodeScanner !== null) html5QrcodeScanner.pause(true);
    };
    const [curTab, setCurTab] = React.useState('warehouse');

    const handleChangeTab = (event, newValue) => {
        setCurTab(newValue);
    };

    function ajax(url, callback) {
        const xhttp = new XMLHttpRequest();
        xhttp.onload = function () {
            callback(JSON.parse(this.responseText));
        };
        xhttp.onerror = function () {
            callback(null);

            let _curEdit = { ...curEdit };
            _curEdit.type = 'error';
            _curEdit.error = 'Ein Fehler ist aufgetreten bitte versuch es nochmal';
            setCurEdit(_curEdit);
        };
        xhttp.open('GET', url);
        xhttp.send();
    }

    function refreshWarehouse(callback) {
        setLoading(true);

        ajax(backendURL + 'list', (response) => {
            setWarehouseList(response);

            setLoading(false);

            if (callback !== undefined) {
                callback(response);
            }
        });
    }

    function addAmount(item, amount, callback) {
        setLoading(true);

        ajax(backendURL + 'add?code=' + encodeURI(item.code) + '&amount=' + amount, (response) => {
            setWarehouseList(response);

            refreshWarehouse(() => {
                if (response !== null && callback !== undefined) {
                    callback();
                }

                setLoading(false);
            });
        });

        /*refreshWarehouse(() => {
            setLoading(true);

            setTimeout(() => {
                for (const [key, _item] of Object.entries(_warehouseList)) {
                    if (_item.code === item.code) {
                        _warehouseList[key].amount = amount;

                        break;
                    }
                }

                if (item !== null) {
                    setWarehouseList(_warehouseList);
                    if (callback) callback();
                } else {
                }

                setLoading(false);
            }, 20);
        });*/
    }

    function createItem(code, name, callback) {
        setLoading(true);

        ajax(backendURL + 'add?code=' + encodeURI(code) + '&name=' + encodeURI(name) + '&amount=0', (response) => {
            refreshWarehouse(() => {
                if (response !== null && callback !== undefined) {
                    let item = {};
                    item.code = code;
                    item.name = name;
                    item.amount = 0;

                    if (callback) callback(item);
                }

                setLoading(false);
            });
        });
    }

    function editItem(item, code, name, amount) {
        setLoading(true);

        ajax(backendURL + 'edit?code=' + encodeURI(code) + '&name=' + encodeURI(name) + '&amount=' + amount, (response) => {
            refreshWarehouse(() => {
                let _curEdit = { ...curEdit };
                _curEdit.type = 'none';
                setCurEdit(_curEdit);

                setLoading(false);
            });
        });
    }

    function deleteItem(item) {
        refreshWarehouse(() => {
            setLoading(true);

            setTimeout(() => {
                let edited = false;
                for (const [key, _item] of Object.entries(_warehouseList)) {
                    if (_item.code === item.code) {
                        delete _warehouseList[key];

                        edited = true;
                        break;
                    }
                }

                if (edited) {
                    setWarehouseList(_warehouseList);

                    let _curEdit = { ...curEdit };
                    _curEdit.type = 'none';
                    setCurEdit(_curEdit);
                } else {
                    let _curEdit = { ...curEdit };
                    _curEdit.type = 'error';
                    _curEdit.error = 'Ein Fehler ist aufgetreten bitte versuch es nochmal';
                    setCurEdit(_curEdit);
                }
                setLoading(false);
            }, 20);
        });
    }

    useEffect(() => {
        refreshWarehouse();
    }, []);

    function openCodeScanner() {
        setOpen(true);
        if (html5QrcodeScanner === null) {
            function onScanSuccess(decodedText, decodedResult) {
                // handle the scanned code as you like, for example:
                console.log(`Code matched = ${decodedText}`, decodedResult);
                //setCurTab('warehouse');

                handleClose();
                window.navigator.vibrate(100);
                qrScanEvent(decodedText);
                qrScanEvent = () => {};
            }

            function onScanFailure(error) {
                // handle scan failure, usually better to ignore and keep scanning.
                // for example:
                //console.warn(`Code scan error = ${error}`);
            }

            html5QrcodeScanner = new Html5QrcodeScanner(
                'reader',
                {
                    fps: 10,
                    /*qrbox: { width: 200, height: 200 },*/
                    experimentalFeatures: {
                        useBarCodeDetectorIfSupported: true,
                    },
                },
                /* verbose= */ false
            );
            html5QrcodeScanner.render(onScanSuccess, onScanFailure);
        } else {
            html5QrcodeScanner.resume();
        }
    }

    function renderTab() {
        return (
            <React.Fragment>
                <Fade direction="up" in={curTab === 'add'} unmountOnExit sx={{ position: 'absolute', width: '100%', left: 0 }}>
                    <Box className="bigButtonBox">
                        <Button
                            variant="contained"
                            endIcon={<AddIcon />}
                            onClick={() => {
                                qrScanEvent = (code) => {
                                    refreshWarehouse((_warehouseList) => {
                                        let item = null;

                                        for (const [key, _item] of Object.entries(_warehouseList)) {
                                            if (key === code) {
                                                item = _item;
                                                break;
                                            }
                                        }

                                        if (item !== null) {
                                            let _curEdit = { ...curEdit };
                                            _curEdit.type = 'add';
                                            item.code = code;
                                            _curEdit.item = item;
                                            setCurEdit(_curEdit);
                                            /*setTimeout(() => {
                                                inputAmountRef.current.form.focus();
                                            }, 500);*/
                                        } else {
                                            let _curEdit = { ...curEdit };
                                            _curEdit.type = '404';
                                            _curEdit.code = code;
                                            setCurEdit(_curEdit);
                                        }
                                    });
                                };

                                openCodeScanner();
                            }}
                        >
                            Objekt hinzufügen
                        </Button>
                    </Box>
                </Fade>
                <Fade direction="up" in={curTab === 'remove'} unmountOnExit sx={{ position: 'absolute', width: '100%', left: 0 }}>
                    <Box className="bigButtonBox">
                        <Button
                            variant="contained"
                            color="error"
                            onClick={() => {
                                qrScanEvent = (code) => {
                                    refreshWarehouse((_warehouseList) => {
                                        let item = null;

                                        for (const [key, _item] of Object.entries(_warehouseList)) {
                                            if (key === code) {
                                                item = _item;
                                                break;
                                            }
                                        }

                                        if (item !== null) {
                                            let _curEdit = { ...curEdit };
                                            _curEdit.type = 'remove';
                                            item.code = code;
                                            _curEdit.item = item;
                                            setCurEdit(_curEdit);
                                        } else {
                                            let _curEdit = { ...curEdit };
                                            _curEdit.type = 'remove_404';
                                            _curEdit.code = code;
                                            setCurEdit(_curEdit);
                                        }
                                    });
                                };

                                openCodeScanner();
                            }}
                            endIcon={<RemoveIcon />}
                        >
                            Objekt entfernen
                        </Button>
                    </Box>
                </Fade>
                <Fade direction="up" in={curTab === 'warehouse'} unmountOnExit sx={{ position: 'absolute', width: '100%', left: 0 }}>
                    <Box className="tableBox">
                        <p>Lager System Inhalte:</p>

                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 500 }} size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Produkt</TableCell>
                                        <TableCell align="right">Anzahl</TableCell>
                                        <TableCell align="right">Code</TableCell>
                                        <TableCell align="right">Bearbeiten</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Object.keys(warehouseList).map((key) => {
                                        let row = warehouseList[key];
                                        return (
                                            <TableRow key={row.name + key} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell component="th" scope="row">
                                                    {row.name}
                                                </TableCell>
                                                <TableCell align="right">{row.amount}</TableCell>
                                                <TableCell align="right">{key}</TableCell>
                                                <TableCell align="right">
                                                    <IconButton
                                                        color="warning"
                                                        onClick={() => {
                                                            refreshWarehouse((_warehouseList) => {
                                                                let item = null;

                                                                for (const [_key, _item] of Object.entries(_warehouseList)) {
                                                                    if (_key === key) {
                                                                        item = _item;
                                                                        break;
                                                                    }
                                                                }

                                                                if (item !== null) {
                                                                    let _curEdit = { ...curEdit };
                                                                    _curEdit.type = 'edit';
                                                                    item.code = key;
                                                                    _curEdit.item = item;
                                                                    setCurEdit(_curEdit);
                                                                } else {
                                                                    let _curEdit = { ...curEdit };
                                                                    _curEdit.type = '404';
                                                                    _curEdit.code = key;
                                                                    setCurEdit(_curEdit);
                                                                }
                                                            });
                                                        }}
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Fade>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <CssBaseline />
            <Backdrop sx={{ color: '#0af', zIndex: 2000 }} open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Dialog open={curEdit.type === 'add'} onClose={closeCurEdit} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{curEdit.item.name}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">Wie viele sollen hinzugefügt werden?</DialogContentText>
                    <Box my={2}>
                        <TextField
                            label="Anzahl"
                            defaultValue={1}
                            inputRef={inputAmountRef}
                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', step: 1 }}
                            onChange={(event) => {
                                let val = event.target.value.replace('.', '').replace(',', '').replace('-', '');

                                inputAmountRef.current.value = val;
                            }}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeCurEdit} color="error">
                        Abbrechen
                    </Button>
                    <Button
                        onClick={() => {
                            let val = parseInt(inputAmountRef.current.value);

                            if (val !== NaN && val > 0)
                                addAmount(curEdit.item, val, () => {
                                    closeCurEdit();
                                });
                        }}
                        color="success"
                    >
                        Hinzufügen
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={curEdit.type === 'create'} onClose={closeCurEdit} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{curEdit.code}</DialogTitle>
                <DialogContent>
                    <Box my={2}>
                        <TextField inputRef={inputCodeRef} label="Code" defaultValue={curEdit.code} />
                    </Box>

                    <TextField inputRef={inputNameRef} label="Name" />
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeCurEdit} color="error">
                        Abbrechen
                    </Button>
                    <Button
                        onClick={() => {
                            let code = inputCodeRef.current.value;
                            let name = inputNameRef.current.value;
                            if (code && name && code.length > 0 && name.length > 0)
                                createItem(code, name, (item) => {
                                    let _curEdit = { ...curEdit };
                                    _curEdit.type = 'add';
                                    _curEdit.item = item;
                                    setCurEdit(_curEdit);
                                });
                        }}
                        color="success"
                    >
                        Hinzufügen
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={curEdit.type === 'edit'} onClose={closeCurEdit} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{curEdit.item.name}</DialogTitle>
                <DialogContent>
                    <Box my={2}>
                        <TextField inputRef={inputCodeEditRef} label="Code" defaultValue={curEdit.item.code} />
                    </Box>

                    <Box mb={2}>
                        <TextField inputRef={inputNameEditRef} label="Name" defaultValue={curEdit.item.name} />
                    </Box>
                    <TextField
                        label="Anzahl"
                        defaultValue={curEdit.item.amount}
                        inputRef={inputAmountEditRef}
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', step: 1 }}
                        onChange={(event) => {
                            let val = event.target.value.replace('.', '').replace(',', '').replace('-', '');

                            inputAmountEditRef.current.value = val;
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        color="warning"
                        onClick={() => {
                            let _curEdit = { ...curEdit };
                            _curEdit.type = 'deleteQ';

                            setCurEdit(_curEdit);
                        }}
                    >
                        Löschen
                    </Button>
                    <Button onClick={closeCurEdit} color="error">
                        Abbrechen
                    </Button>
                    <Button
                        onClick={() => {
                            let code = inputCodeEditRef.current.value;
                            let name = inputNameEditRef.current.value;
                            let amount = parseInt(inputAmountEditRef.current.value);

                            if (code && name && amount != NaN && code.length > 0 && name.length > 0 && amount >= 0) editItem(curEdit.item, code, name, amount);
                        }}
                        color="success"
                    >
                        Speichern
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={curEdit.type === 'remove'} onClose={closeCurEdit} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{curEdit.item.name}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">Wie viele sollen entnommen werden?</DialogContentText>
                    <Box my={2}>
                        <TextField
                            label="Anzahl"
                            defaultValue={1}
                            inputRef={inputAmountRef}
                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', step: 1 }}
                            onChange={(event) => {
                                let val = event.target.value.replace('.', '').replace(',', '').replace('-', '');

                                inputAmountRef.current.value = val;
                            }}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeCurEdit} color="error">
                        Abbrechen
                    </Button>
                    <Button
                        onClick={() => {
                            let val = parseInt(inputAmountRef.current.value);
                            if (val !== NaN && val > 0) {
                                addAmount(curEdit.item, -val, () => {
                                    closeCurEdit();
                                });
                            }
                        }}
                        color="success"
                    >
                        Entnehmen
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={curEdit.type === '404'} onClose={closeCurEdit} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle color="error">Eintrag erstellen?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">Fehler: Code wurde nicht im System gefunden.</DialogContentText>
                    <DialogContentText id="alert-dialog-description" color="info">
                        Code "{curEdit.code}"
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeCurEdit} color="error">
                        Abbrechen
                    </Button>
                    <Button
                        onClick={() => {
                            let _curEdit = { ...curEdit };
                            _curEdit.type = 'create';

                            setCurEdit(_curEdit);
                        }}
                        color="success"
                    >
                        Hinzufügen
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={curEdit.type === 'remove_404'} onClose={closeCurEdit} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle color="error">Code nicht im System</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">Fehler: Code wurde nicht im System gefunden.</DialogContentText>
                    <DialogContentText id="alert-dialog-description" color="info">
                        Code "{curEdit.code}"
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeCurEdit} color="error">
                        Verstanden
                    </Button>
                </DialogActions>
            </Dialog>{' '}
            <Dialog open={curEdit.type === 'error'} onClose={closeCurEdit} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle color="error">Fehler</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{curEdit.error}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeCurEdit} color="error">
                        Verstanden
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={curEdit.type === 'deleteQ'} onClose={closeCurEdit} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle color="error">Willst du den Eintrag wirklich entfernen?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{curEdit.item.name}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeCurEdit} color="error">
                        Abbrechen
                    </Button>
                    <Button
                        onClick={() => {
                            deleteItem(curEdit.item);
                        }}
                        color="warning"
                    >
                        Löschen
                    </Button>
                </DialogActions>
            </Dialog>
            <div className="App">
                <div id="ScanDialogBackdrop" className={open === false ? 'ScanDialogBackdropClose' : null} onClick={handleClose}></div>
                <Box id={'ScanDialog'} className={open === false ? 'ScanDialogClose' : null}>
                    <Paper elevation={3}>
                        <div id="reader"></div>
                        <Box mb={1}>
                            <Button
                                variant="contained"
                                color="error"
                                onClick={() => {
                                    handleClose();
                                }}
                            >
                                Abbrechen
                            </Button>
                        </Box>
                    </Paper>
                </Box>
                <Container>
                    <Box sx={{ position: 'relative' }}>{renderTab()}</Box>
                </Container>
                <BottomNavigation className="navBar" value={curTab} onChange={handleChangeTab}>
                    <BottomNavigationAction sx={{ color: '#aaa' }} label="Lager" value="warehouse" icon={<WarehouseIcon />} />
                    <BottomNavigationAction sx={{ color: '#3f3' }} label="Eintragen" value="add" icon={<AddIcon />} />
                    <BottomNavigationAction sx={{ color: '#f33' }} label="Austragen" value="remove" icon={<RemoveIcon />} />
                </BottomNavigation>
            </div>
        </React.Fragment>
    );
}

export default App;
